// @flow
import React, { useState } from 'react';
import { navigate } from '@reach/router';
import cx from 'classnames';

import { gtag } from '../../../../utils';

import Layout from '../../../../components/Layout/Layout';
import HeaderEmail from '../../../../components/Email/HeaderEmail';
import FooterEmail from '../../../../components/Email/FooterEmail';

import styles from '../unsubscribe.module.scss';

const optionReasonList = [
  'high_frequency',
  'too_long',
  'not_interested',
  'other',
];
const optionReasonMap = {
  high_frequency: '配信回数が多い',
  too_long: 'メールが長い',
  not_interested: '内容に興味がない',
  other: 'その他（任意）',
};

const questions = [
  {
    id: 'reason',
    isRequired: true,
    isOptions: true,
    options: optionReasonList,
    optionTexts: optionReasonMap,
    text: '配信を停止した理由を教えてください。',
  },
  {
    id: 'paidy-info-request',
    isRequired: false,
    isOptions: false,
    text: 'ペイディに関してどのような情報が知りたいですか？（任意）',
  },
];

const RadioOption = ({ value, mapper, onChange }) => (
  <label htmlFor={`cb_${value}`} className="radio email">
    <input
      type="radio"
      id={`cb_${value}`}
      name="reason"
      value={value}
      onChange={onChange}
    />
    <span className="text">{mapper ? mapper[value] : value}</span>
    <i className="icon"></i>
  </label>
);

const UnsubscribeSuccess = () => {
  const [answers, setAnswers] = useState({
    reason: '',
    'reason-other': '',
    'paidy-info-request': '',
  });

  const onSubmit = async (event) => {
    event.preventDefault();

    gtag({
      event: 'user_action',
      ga_action: 'click_unsubscribe_reason',
      ga_label: answers['reason-other'] || optionReasonMap[answers.reason],
    });

    if (answers['paidy-info-request']) {
      gtag({
        event: 'user_action',
        ga_action: 'click_unsubscribe_information_request',
        ga_label: answers['paidy-info-request'],
      });
    }

    navigate('/email/unsubscribe/thank-you');
  };

  const onAnswer = (id, event) => {
    setAnswers({
      ...answers,
      [id]: event.target.value,
    });
  };

  return (
    <Layout hasNoHeader hasNoFooter>
      <div className={styles.wrapper}>
        <HeaderEmail className={styles.header} />
        <div className={styles.content}>
          <img
            className={styles.headImg}
            src={require('../../../../images/icon-check-circle-big.svg')}
            height="75px"
            width="75px"
            alt="Check icon"
            loading="lazy"
          />
          <br />
          <h2 className={styles.heading}>
            プロモーションメールの配信停止が完了しました。
          </h2>
          <p className={styles.p}>
            今後もペイディでお買いものをお楽しみください。
          </p>
          <div className={styles.divider}></div>
          <form className={styles.form} onSubmit={onSubmit}>
            <h3>
              お客様の大切な声を、今後のサービス向上に活かしてまいります。ぜひご意見をお聞かせください。
            </h3>
            {questions.map((question, index) => (
              <div
                key={`unsub-question-${question.id}-${index}`}
                className={styles.question}
              >
                <div className={styles.questionText}>
                  <span className={styles.index}>
                    <span>{index + 1}</span>
                  </span>
                  {question.text}
                </div>
                {question.options
                  ? question.options.map((option, optionIndex) => (
                      <RadioOption
                        key={`${question.id}-option-${optionIndex}`}
                        value={option}
                        mapper={question.optionTexts}
                        onChange={(e) => onAnswer(question.id, e)}
                      />
                    ))
                  : null}
                {question.id === 'reason' && answers.reason === 'other' ? (
                  <input
                    type="text"
                    value={answers['reason-other']}
                    onChange={(e) => onAnswer('reason-other', e)}
                    placeholder="ご記入ください。"
                  />
                ) : null}
                {question.id !== 'reason' ? (
                  <input
                    type="text"
                    value={answers[question.id]}
                    onChange={(e) => onAnswer(question.id, e)}
                    placeholder="ご記入ください。"
                  />
                ) : null}
              </div>
            ))}
            <button
              type="submit"
              disabled={!answers.reason}
              className={cx(
                styles.button,
                'email',
                { disable: !answers.reason },
                'btn'
              )}
            >
              送信する
            </button>
          </form>
        </div>
        <FooterEmail className={styles.footer} />
      </div>
    </Layout>
  );
};

export default UnsubscribeSuccess;
